


















































































































































































































































































::v-deep .set-container {
    margin: 20px;
    background: #fff;
    height: calc(100% - 40px);
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    & > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
.edit-box {
    padding: 20px;
    /*width: 60%;*/
    box-sizing: border-box;
}
.info-title {
    /*color: #409eff;*/
    line-height: 40px;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
    position: relative;
    margin-bottom: 10px;
    span {
        background: #fff;
        padding: 0 20px;
        z-index: 1;
        position: relative;
    }
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        height: 1px;
        background: #e6e6e6;
        transform: translateY(-50%);
        z-index: 0;
    }
}
.flex {
    display: flex;
}
